import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GridApi } from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { resolve } from '@angular/compiler-cli';
import { AgCartesianSeriesTooltipRendererParams } from 'ag-charts-enterprise';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private http = inject(HttpClient);

  public $authEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $userData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public $platforms: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $activePlatform: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $changePlatform: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $projects: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public $services: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $locations: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public $chartData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $gridRowsCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $usersApiDateRange: BehaviorSubject<any> = new BehaviorSubject<any>(
    'last28days',
  );
  public $dashboardChartLeadFilter: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public $dashboardBusinessMetrics: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  public isAdmin: boolean = false;
  public kpiCache: any = null;
  public $kpiData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public $updateKpiData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public $manualQualifiedActionEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public $message: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public $gridMode: BehaviorSubject<any> = new BehaviorSubject<any>('lead');

  public gridApi: GridApi | undefined;

  public $old_kpi_link: BehaviorSubject<any> = new BehaviorSubject<any>('');

  public startDate: any;

  public months: any[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  public fixedColors: any = {
    // non-paid  traffic
    'organic_search': ['#7986FF', '#F4F5FF'],
    'non_paid_budget': ['#7986FF', '#F4F5FF'],
    'non_paid_mqls_organic_search': ['#7986FF', '#F4F5FF'],
    'non_paid_unique_conversions_organic_search': ['#7986FF', '#F4F5FF'],
    'direct': ['#94D8FF', '#E1F4FF'], // dashboard
    'non_paid_mqls_direct': ['#94D8FF', '#E1F4FF'], // dashboard
    'non_paid_unique_conversions_direct': ['#94D8FF', '#E1F4FF'], // dashboard
    'non_paid_traffic_direct': ['#94D8FF', '#E1F4FF'],
    'gbp_organic': ['#AE9AFD', '#F4F1FF'],
    'non_paid_mqls_gbp_organic': ['#AE9AFD', '#F4F1FF'],
    'other_non_paid': ['#C9CEFF', '#F9F8FB'], // dashboard
    'non_paid_traffic_other': ['#C9CEFF', '#F9F8FB'],
    'non_paid_mqls_other_non_paid': ['#C9CEFF', '#F9F8FB'],
    'non_paid_unique_conversions_other_non_paid': ['#C9CEFF', '#F9F8FB'],
    'non_paid_traffic_blog': ['#7986FF', '#F4F5FF'],
    'non_paid_traffic_non_blog': ['#AE9AFD', '#F4F1FF'],
    'non_paid_unique_conversions_gbp_organic': ['#AE9AFD', '#F4F1FF'],
    //paid
    'google_ads': ['#FF9894', '#FFF5F4'],
    'google_ads_ppc_spend': ['#FF9894', '#FFF5F4'],
    'paid_mqls_google_ads': ['#FF9894', '#FFF5F4'],
    'paid_unique_conversions_google_ads': ['#FF9894', '#FFF5F4'],
    'google_lsa': ['#BCD45C', '#E9F6CE'],
    'google_ads_ppc_spend_lsa': ['#BCD45C', '#E9F6CE'],
    'paid_mqls_google_lsa': ['#BCD45C', '#E9F6CE'],
    'paid_unique_conversions_google_lsa': ['#BCD45C', '#E9F6CE'],
    'microsoft_ads': ['#FFA179', '#FFF9F4'],
    'microsoft_ads_ppc_spend': ['#FFA179', '#FFF9F4'],
    'paid_mqls_microsoft_ads': ['#FFA179', '#FFF9F4'],
    'paid_unique_conversions_microsoft_ads': ['#FFA179', '#FFF9F4'],
    'facebook_ads': ['#FECF9E', '#FFFDF4'],
    'facebook_ads_ppc_spend': ['#FECF9E', '#FFFDF4'],
    'paid_mqls_facebook_ads': ['#FECF9E', '#FFFDF4'],
    'paid_unique_conversions_facebook_ads': ['#FECF9E', '#FFFDF4'],
    'other_paid': ['#FFECA7', '#FDFAF0'],
    'ppc_management': ['#FFECA7', '#FDFAF0'],
    'paid_mqls_other_paid': ['#FFECA7', '#FDFAF0'],
    'paid_unique_conversions_other_paid': ['#FFECA7', '#FDFAF0'],
    'call': ['#5C85D4', '#CEDCF6'],
    'form': ['#D45C5C', '#F6CECE'],
    'chat': ['#D4B25C', '#F6EBCE'],
    'blended': ['#D4B25C', '#F6EBCE'],
    'roi': ['#D4B25C', '#F6EBCE'],
    'blended_cost_per_mql': ['#D4B25C', '#F6EBCE'],
    'non_paid': ['#5C85D4', '#CEDCF6'],
    'non_paid_cost_per_mql': ['#7986FF', '#F4F5FF'],
    'roi_non_paid': ['#7986FF', '#F4F5FF'],
    'paid': ['#D45C5C', '#F6CECE'],
    // 'paid_cost_per_mql': ['#FF9894', '#FFF5F4'],
    'paid_cost_per_mql': ['#BCD45C', '#E9F6CE'],
    'roi_paid': ['#BCD45C', '#FFF5F4'],
    'paid_traffic': ['#FF9894', '#FFF5F4'],
    'opportunity_non_paid': ['#7986FF', '#F4F5FF'],
    'opportunity_paid': ['#BCD45C', '#FFF5F4'],
    'opportunity': ['#D4B25C', '#F6EBCE'],
    ////// conversions

    'service_location': [
      // priority
      ['#5C85D4', '#CEDCF6'],
      ['#D45C5C', '#F6CECE'],
      ['#D4B25C', '#F6EBCE'],
      ['#BCD45C', '#E9F6CE'],
      ['#66D45C', '#CEF6D7'],
      ['#5CD4BE', '#CEF6EA'],
      ['#5CB0D4', '#CEDEF6'],
      ['#6D5CD4', '#D4CEF6'],
      ['#B55CD4', '#F3CEF6'],
      ['#D45C87', '#F6CED8'],
      // other pool
      ['#FFECA7', '#FDFAF0'],
      ['#FECF9E', '#FFFDF4'],
      ['#FFA179', '#FFF9F4'],
      ['#FF97AA', '#FFF2F4'],
      ['#FF9894', '#FFF5F4'],
      ['#E7A3FF', '#F8E4FF'],
      ['#AE9AFD', '#F4F1FF'],
      ['#C9CEFF', '#F9F8FB'],
      ['#94D8FF', '#E1F4FF'],
      ['#7986FF', '#F4F5FF'],
    ],
    'service_locationOLD': [
      // priority
      ['#BCD45C', '#E9F6CE'],
      ['#66D45C', '#CEF6D7'],
      ['#5CD4BE', '#CEF6EA'],
      ['#5CB0D4', '#CEDEF6'],
      ['#6D5CD4', '#D4CEF6'],
      ['#B55CD4', '#F3CEF6'],
      ['#D45C87', '#F6CED8'],
      // other pool
      ['#7986FF', '#F4F5FF'],
      ['#FECF9E', '#FFFDF4'],
      ['#FF9894', '#FFF5F4'],
      ['#94D8FF', '#E1F4FF'],
      ['#FFA179', '#FFF9F4'],
      ['#C9CEFF', '#F9F8FB'],
      ['#AE9AFD', '#F4F1FF'],
      ['#E7A3FF', '#F8E4FF'],
      ['#FF97AA', '#FFF2F4'],
      ['#FFECA7', '#FDFAF0'],
      ['#5C85D4', '#CEDCF6'],
      ['#D45C5C', '#F6CECE'],
      ['#D4B25C', '#F6EBCE'],
    ]
  }

  public colors_for_roi: any[] = [
    ['#D45C5C', '#F6CECE'],
    ['#FFA179', '#FFF9F4'],
    ['#AE9AFD', '#F4F1FF'],
  ];
  public colors: any[] = [
    ['#7986FF', '#F4F5FF'],
    ['#FECF9E', '#FFFDF4'],
    ['#FF9894', '#FFF5F4'],
    ['#94D8FF', '#E1F4FF'],
    ['#FFA179', '#FFF9F4'],
    ['#C9CEFF', '#F9F8FB'],
    ['#AE9AFD', '#F4F1FF'],
    ['#E7A3FF', '#F8E4FF'],
    ['#FF97AA', '#FFF2F4'],
    ['#FFECA7', '#FDFAF0'],
    ['#5C85D4', '#CEDCF6'],
    ['#D45C5C', '#F6CECE'],
    ['#D4B25C', '#F6EBCE'],
    ['#BCD45C', '#E9F6CE'],
    ['#66D45C', '#CEF6D7'],
    ['#5CD4BE', '#CEF6EA'],
    ['#5CB0D4', '#CEDEF6'],
    ['#6D5CD4', '#D4CEF6'],
    ['#B55CD4', '#F3CEF6'],
    ['#D45C87', '#F6CED8'],
  ];

  constructor() {
    // this.$activePlatform.pipe(tap((value: any) => {
    //   console.log('$activePlatform', value);
    //   return value;
    // }));
  }

  // getServicesAndLocations
  public getCurrentPlatformData() {
    return new Promise(resolve => {
      const platform = this.$activePlatform.value || localStorage.getItem('platform');
      if (platform) {
        this.http
          .get(
            environment.API_PATH +
            `/platforms/${platform}?data={"select":"old_kpi services locations automatic_lead_qualification_start"}`,
          )
          .subscribe((res: any) => {
            this.startDate = res.automatic_lead_qualification_start;
            this.$old_kpi_link.next(res.old_kpi);
            this.$services.next(res.services);
            this.$locations.next(res.locations);
            resolve(true);
          });
      }
    });
  }

  public getDateRange(dateRange: string) {
    if (dateRange === 'all') {
      return {
        startDate: '2020-01-01T00:00:00.000Z',
        endDate: new Date().toISOString(),
      };
    } else if (dateRange === 'last28days') {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 28);
      return {
        startDate: startDate.toISOString(),
        endDate: new Date().toISOString(),
      };
    } else {
      return null;
    }
  }

  public getKpiData() {
    this.http
      .post(environment.API_PATH + '/composite/views/agGrid/kpi', {
        startRow: 0,
        endRow: 30,
        platform: {
          _id: this.$activePlatform.value || localStorage.getItem('platform'),
        },
      })
      .subscribe((kpi: any) => {
        this.kpiCache = kpi;
        this.$kpiData.next(kpi.rows);
      });
  }

  public getAllPlatforms() {
    this.http
      .get(environment.API_PATH + '/platforms?data={"select":"_id url locations automatic_lead_qualification_start setup"}')
      .subscribe((res: any) => {
        const connectedPlatforms = res.filter(
          (platform: any) => platform.setup,
        );
        this.$platforms.next(connectedPlatforms);
      });
  }

  public generateRandomLightColor() {
    let color = '#';
    const brightness = Math.floor(Math.random() * 100) + 100;
    let component = brightness.toString(16);
    color += component.length === 1 ? '0' + component : component;

    for (let i = 0; i < 2; i++) {
      const random = Math.floor(Math.random() * (255 - brightness));
      component = (brightness + random).toString(16);
      color += component.length === 1 ? '0' + component : component;
    }

    return color;
  }

  public checkActiveGrid(gridApi: any): boolean {
    return !!(gridApi && gridApi.destroyCalled === false);
  }

  private getTooltipItemPrefix(params: AgCartesianSeriesTooltipRendererParams): string {
    if (['investment', 'blended_cost_per_mql', 'opportunity'].includes(params.yKey.split('.')[0])) {
      return '$';
    } else {
      return '';
    }
  }

  private getTooltipItemSuffix(params: AgCartesianSeriesTooltipRendererParams): string {
    if (['roi'].includes(params.yKey.split('.')[0])) {
      return '%';
    } else {
      return '';
    }
  }

  private makeTooltipItemName(key: string): string {
    const keyToName = new Map();
    keyToName.set('non_paid_mqls_organic_search', 'Organic Search');
    keyToName.set('non_paid_mqls_direct', 'Direct');
    keyToName.set('non_paid_mqls_gbp_organic', 'GBP Organic');
    keyToName.set('non_paid_mqls_other_non_paid', 'Other');
    keyToName.set('paid_mqls_microsoft_ads', 'Microsoft Ads');
    keyToName.set('paid_mqls_google_ads', 'Google Ads');
    keyToName.set('paid_mqls_facebook_ads', 'Facebook Ads');
    keyToName.set('opportunity_non_paid', 'Non-Paid Projected Revenue');
    keyToName.set('opportunity_paid', 'Paid Projected Revenue');
    keyToName.set('opportunity', 'Total Projected Revenue');
    keyToName.set('paid_traffic', 'Traffic');
    keyToName.set('non_paid_traffic_blog', 'Blog');
    keyToName.set('non_paid_traffic_non_blog', 'Non-Blog');
    keyToName.set('non_paid_traffic_direct', 'Direct');
    keyToName.set('non_paid_traffic_other', 'Other');
    keyToName.set('paid_unique_conversions_google_ads', 'Google Ads');
    keyToName.set('paid_unique_conversions_google_lsa', 'Google LSA');
    keyToName.set('paid_unique_conversions_microsoft_ads', 'Microsoft Ads');
    keyToName.set('paid_unique_conversions_facebook_ads', 'Facebook Ads');
    keyToName.set('non_paid_unique_conversions_organic_search', 'Organic Search');
    keyToName.set('non_paid_unique_conversions_direct', 'Direct');
    keyToName.set('non_paid_unique_conversions_gbp_organic', 'GBP Organic');
    keyToName.set('non_paid_unique_conversions_other', 'Other');
    keyToName.set('paid_mqls_google_lsa', 'Google LSA');
    keyToName.set('paid_mqls_other_paid', 'Other');
    keyToName.set('ppc_management', 'PPC Management');
    keyToName.set('google_ads_ppc_spend', 'Google Ads');
    keyToName.set('google_ads_ppc_spend_lsa', 'Google LSA');
    keyToName.set('microsoft_ads_ppc_spend', 'Microsoft Ads');
    keyToName.set('facebook_ads_ppc_spend', 'Facebook Ads');
    keyToName.set('non_paid_budget', 'Budget');
    keyToName.set('blended_cost_per_mql', 'Blended Cost Per MQL');
    keyToName.set('non_paid_cost_per_mql', 'Non-Paid Cost Per MQL');
    keyToName.set('paid_cost_per_mql', 'Paid Cost Per MQL');
    keyToName.set('roi_non_paid', 'Non-Paid ROI');
    keyToName.set('roi_paid', 'Paid ROI');
    keyToName.set('roi', 'Blended Projected ROI');
    keyToName.set('non_paid_unique_conversions_other_non_paid', 'Other');
    keyToName.set('paid_unique_conversions_other_paid', 'Other');
    keyToName.set('other_non_paid', 'Other');
    keyToName.set('organic_search', 'Organic Search');
    keyToName.set('google_ads', 'Google Ads');
    keyToName.set('google_lsa', 'Google LSA');
    keyToName.set('gbp_organic', 'GBP Organic');
    keyToName.set('facebook_ads', 'Facebook Ads');
    keyToName.set('direct', 'Direct');

    if (keyToName.has(key.replace('_compare_mode', ''))) {
      return keyToName.get(key.replace('_compare_mode', '')).trim();
    }
    return key.replace('_compare_mode', '').trim();
  }

  private tooltipSplitByGroup(allValues: any, activeFilters: any, totals: number) {
    let paidTotal = 0;
    let nonPaidTotal = 0;

    const nonPaidGroup = [];
    const paidGroup = [];
    for (const [key, value] of Object.entries<any>(allValues)) {
      if (activeFilters.includes(key) && value) {
        if (['google_ads', 'facebook_ads', 'microsoft_ads', 'other_paid', 'google_lsa'].includes(key.replace('_compare_mode', ''))) {
          paidGroup.push({
            name: this.makeTooltipItemName(key)
              .replace(/_/g, ' '),
            key,
            value,
            percentage: ((Number(value) / Number(totals)) * 100).toFixed()
          });
        } else if (key.includes('non_paid') || ['direct', 'gbp_organic', 'organic_search'].includes(key.replace('_compare_mode', ''))) {
          nonPaidGroup.push({
            name: this.makeTooltipItemName(key)
              .replace(/_/g, ' '),
            key,
            value,
            percentage: ((Number(value) / Number(totals)) * 100).toFixed()
          });
        } else {
          paidGroup.push({
            name: this.makeTooltipItemName(key)
              .replace('_compare_mode', '')
              .replace(/_/g, ' '),
            key,
            value,
            percentage: ((Number(value) / Number(totals)) * 100).toFixed()
          });
        }
        // if (!['google_ads', 'facebook_ads', 'microsoft_ads', 'other_paid', 'google_lsa'].includes(key) || key.includes('non_paid')) {
        //   nonPaidGroup.push({
        //     name: this.makeTooltipItemName(key)
        //       .replace(/_/g, ' '),
        //     key,
        //     value,
        //     percentage: ((Number(value) / Number(totals)) * 100).toFixed()
        //   });
        // } else {
        //   paidGroup.push({
        //     name: this.makeTooltipItemName(key)
        //       .replace(/_/g, ' '),
        //     key,
        //     value,
        //     percentage: ((Number(value) / Number(totals)) * 100).toFixed()
        //   });
        // }
      }
    }

    for (const item of paidGroup) { paidTotal += item.value; }
    for (const item of nonPaidGroup) { nonPaidTotal += item.value; }

    return { nonPaidGroup, paidGroup, paidTotal, nonPaidTotal };
  }

  public tooltipRenderer(filterType: string, options: any, params: AgCartesianSeriesTooltipRendererParams) {
    let content = '';
    let contentHtml = '';
    const title = `${params.datum[params.xKey]}`;
    const titleHtml = `<div class="ag-chart-tooltip-title" style="background-color: #7987FF">${title}</div>`;

    const allValues = params.datum[params.yKey.split('.')[0]];
    if (filterType === 'target') {
      // options.axes[0].crosshair.enabled = false;
      content += `<div style="padding-left: 0;" class="chart-tooltip__item ag-chart-tooltip-content__bold"><div>Target MQLs: ${allValues.target_mqls?.toLocaleString('en', { maximumFractionDigits: 2 })}</div></div>`;
      content += `<div style="padding-left: 0;" class="chart-tooltip__item"><div>Paid: ${allValues.target_mqls_paid?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0}</div></div>`;
      content += `<div style="padding-left: 0;" class="chart-tooltip__item"><div>Non-paid: ${allValues.target_mqls_non_paid?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0}</div></div>`;

      contentHtml = `<div class="ag-chart-tooltip-content">${content}</div>`;
      return titleHtml + contentHtml;
    }

    const activeFilters = options.series.filter((x: any) => x.visible).map((x: any) => x.yKey.split('.')[1]);
    const hoveredItem = params.yKey.split('.')[1];
    let allTotals = 0;

    const prefix = this.getTooltipItemPrefix(params);
    const suffix = this.getTooltipItemSuffix(params);

    for (const [key, value] of Object.entries(allValues)) {
      if (activeFilters.includes(key)) { allTotals += Number(value) || 0; }
    }

    const { paidGroup, nonPaidGroup, paidTotal, nonPaidTotal } = this.tooltipSplitByGroup(allValues, activeFilters, allTotals);

    // console.log('✳️ paidGroup', paidGroup);
    // console.log('✳️ nonPaidGroup', nonPaidGroup);
    // console.log('✳️ options', options);

    if (['services', 'locations', 'types'].includes(filterType)) { // dashboard
      content += `<b>Total: ${prefix}${(nonPaidTotal + paidTotal).toLocaleString('en', { maximumFractionDigits: 2 })}${suffix}</b><div class="chart-total-separator"></div>`;
      for (const item of nonPaidGroup.concat(paidGroup)) {
        const color = options.series.find((s: any) => s.yKey.split('.')[1] === item.key)?.fill;
        content += `<div class="chart-tooltip__item ${hoveredItem === item.key ? 'ag-chart-tooltip-content__bold' : ''}"><span style="background-color: ${color}" class="chart-tooltip__color-box"></span><div>${item.name}: ${prefix}${item.value.toLocaleString('en', { maximumFractionDigits: 2 })}${suffix}</div></div>`;
      }
      contentHtml = `<div class="ag-chart-tooltip-content">${content}</div>`;
    } else if (filterType === 'roi' || filterType === 'blended_cost_per_mql' || filterType.includes('opportunity')) {
      for (const item of nonPaidGroup.concat(paidGroup)) {
        const color = options.series.find((s: any) => s.yKey.split('.')[1] === item.key)?.fill;
        content += `<div class="chart-tooltip__item ${hoveredItem === item.key ? 'ag-chart-tooltip-content__bold' : ''}"><span style="background-color: ${color}" class="chart-tooltip__color-box"></span><div>${item.name}: ${prefix}${item.value.toLocaleString('en', { maximumFractionDigits: 2 })}${suffix}</div></div>`;
      }
      contentHtml = `<div class="ag-chart-tooltip-content">${content}</div>`;
    } else {
      if (nonPaidTotal) {
        content += `<b>Non-Paid total: ${prefix}${nonPaidTotal.toLocaleString('en', { maximumFractionDigits: 2 })}${suffix} (${((Number(nonPaidTotal) / Number(allTotals)) * 100).toFixed()}%)</b>`;
        for (const item of nonPaidGroup) {
          const color = options.series.find((s: any) => s.yKey.includes(item.key))?.fill;
          content += `<div class="chart-tooltip__item ${hoveredItem === item.key ? 'ag-chart-tooltip-content__bold' : ''}"><span style="background-color: ${color}" class="chart-tooltip__color-box"></span><div>${item.name}: ${prefix}${item.value.toLocaleString('en', { maximumFractionDigits: 2 })}${suffix} (${item.percentage}%)</div></div>`;
        }
      }

      if (nonPaidTotal && paidTotal) {
        content += '<br>';
      }

      if (paidTotal) {
        content += `<b>Paid total: ${prefix}${paidTotal.toLocaleString('en', { maximumFractionDigits: 2 })}${suffix} (${((Number(paidTotal) / Number(allTotals)) * 100).toFixed()}%)</b>`;
        for (const item of paidGroup) {
          const color = options.series.find((s: any) => s.yKey.includes(item.key))?.fill;
          content += `<div class="chart-tooltip__item ${hoveredItem === item.key ? 'ag-chart-tooltip-content__bold' : ''}"><span style="background-color: ${color}" class="chart-tooltip__color-box"></span><div>${item.name}: ${prefix}${item.value.toLocaleString('en', { maximumFractionDigits: 2 })}${suffix} (${item.percentage}%)</div></div>`;
        }
      }

      const allTotalHtml = `<span style="font-weight: bold; font-size: 12px;">Total: ${prefix}${allTotals.toLocaleString('en', { maximumFractionDigits: 2 })}${suffix}</span><br>`;
      contentHtml = `<div class="ag-chart-tooltip-content">${allTotalHtml}<div class="chart-total-separator"></div>${content}</div>`;
    }



    return titleHtml + contentHtml;
  }
}
