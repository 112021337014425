{
  "name": "mt-portal",
  "version": "0.5.77",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration production",
    "build:stage": "ng build --configuration stage",
    "prebuild": "npm --no-git-tag-version version patch",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@ag-grid-community/angular": "^31.3.2",
    "@ag-grid-community/client-side-row-model": "^31.3.2",
    "@ag-grid-community/core": "^31.3.2",
    "@ag-grid-community/styles": "^31.3.2",
    "@ag-grid-enterprise/clipboard": "^31.3.2",
    "@ag-grid-enterprise/core": "^31.3.2",
    "@ag-grid-enterprise/master-detail": "^31.3.2",
    "@ag-grid-enterprise/menu": "^31.3.2",
    "@ag-grid-enterprise/range-selection": "^31.3.2",
    "@ag-grid-enterprise/row-grouping": "^31.3.2",
    "@ag-grid-enterprise/server-side-row-model": "^31.3.2",
    "@ag-grid-enterprise/set-filter": "^31.3.2",
    "@ag-grid-enterprise/status-bar": "^31.3.2",
    "@angular/animations": "^17.1.0",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "ag-charts-angular": "^10.3.0",
    "ag-charts-enterprise": "^10.3.0",
    "jwt-decode": "^4.0.0",
    "primeng": "^17.13.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.3",
    "@angular/cli": "^17.1.3",
    "@angular/compiler-cli": "^17.1.0",
    "@faker-js/faker": "^8.4.1",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^20.12.7",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "3.2.5",
    "typescript": "~5.3.2"
  }
}
